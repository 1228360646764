<template>
  <nav class="header">
    <div class="title">
      <router-link
        class="main-title"
        style="
          text-decoration: none;
          color: #222222;
          display: inline-flex;
          width: 170px;
          align-items: center;
        "
        to="/"
      >
        <img src="../../assets/images/grain-effect.svg" alt="" />
        <h1>idVPN</h1>
      </router-link>
      <router-link
        class="short-title"
        style="text-decoration: none; color: #222222; height: 32px"
        to="/"
      >
        <img src="../../assets/images/grain-effect.svg" alt="" />
      </router-link>
    </div>
    <div class="btn">
      <a
        class="to-hide blog-button"
        href="https://medium.com/@idvpnapp/"
        style="margin-right: 32px"
        >Blog</a
      >
      <a class="to-hide" type="button" href="mailto:support@idvpn.app"
        >Contact</a
      >
      <img
        @click="showMenuModal = true"
        class="burger-menu"
        src="../../assets/images/list.svg"
        alt="List"
      />
    </div>
  </nav>
  <Teleport to="body">
    <MobileMenu :show="showMenuModal" @hideMobileMenu="hideMobileMenu">
    </MobileMenu>
  </Teleport>
</template>

<script>
import MobileMenu from "./MobileMenu.vue";

export default {
  data() {
    return {
      showMenuModal: false,
      isCustomizePage: false,
    };
  },
  props: ["testing"],
  components: {
    MobileMenu,
  },
  methods: {
    hideMobileMenu() {
      this.showMenuModal = false;
    },
  },
  mounted() {
    if (this.$route.path?.includes("blog")) {
      this.isCustomizePage = true;
    } else {
      this.isCustomizePage = false;
    }
  },
  watch: {
    $route() {
      if (this.$route.path?.includes("blog")) {
        this.isCustomizePage = true;
      } else {
        this.isCustomizePage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  background-color: #fefdfa;
  border-bottom: 1px solid #222222;
  .title {
    display: flex;
    align-items: center;
    padding: 32px 0px 31px 64px;
    .short-title {
      display: none;
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
      padding-left: 16px;
      font-weight: 900;
    }
    img {
      width: 32px;
      height: 32px;
    }
  }
  .btn {
    padding: 24px 64px 23px 0px;

    .burger-menu {
      display: none;
    }
    .blog-button {
      background-color: #fff;
      border: #fefdfa;
      box-shadow: none;
      width: auto;
    }
    a {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 12px 0;
      font-weight: 900;
      text-decoration: none;
      border-radius: 24px;
      color: #222222;
      background-color: #e9d974;
      border: #e9d974;
      display: inline-block;
      width: 128px;
      box-shadow: 0px 0px 16px rgba(233, 217, 116, 0.64);
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .title {
      padding: 32px 0px 31px 16px;
      .main-title {
        display: none !important;
      }
      .short-title {
        display: inline-block !important;
      }
      img {
        width: 32px;
        height: 32px;
      }
    }
    .btn {
      padding: 24px 16px 23px 0px;
      display: flex;
      .to-hide {
        display: none;
      }
      .burger-menu {
        display: inline-block;
        box-shadow: 0px 0px 16px rgba(233, 217, 116, 0.64);
        opacity: 1;
        background-color: #e9d974;
        padding: 12px;
        border-radius: 25px;
      }
    }
  }
}
</style>
