<template>
  <div class="primary-container">
    <div class="container">
      <div class="first-container">
        <h1>Terms & Conditions</h1>
        <h5>support@idvpn.app</h5>
      </div>
      <div class="second-container">
        <p>
          Welcome to the idVPN and our IOS mobile application (our “Service”).
          These Terms of Service (“Terms”) apply as between you, the User of
          this Service and idVPN (Serhiy Ozhibko) of Knyahyni Olʹhy, L’viv,
          L’vivs’ka oblast, 79000, Ukraine and email support@idvpn.app
          ("us", "we", or "our"), the provider(s) of this Service. Your
          agreement to comply with and be bound by these Terms is deemed to
          occur upon your first use of the Service. If you do not agree to be
          bound by these Terms, you should stop using the Service immediately.
        </p>
        <p>
          You agree that by accessing the Service, you have read, understood,
          and agree to be bound by all of these Terms. If you do not agree with
          all of these Terms, then you are expressly prohibited from using the
          Service and you must discontinue use immediately.
        </p>
        <p>
          These Terms (the “Terms”) constitute a legal agreement between you and
          us governing the use of idVPN. We license use of idVPN to you on the
          basis of these Terms. We do not sell idVPN to you, and we remain the
          owner of idVPN at all times.
        </p>
        <ol>
          <li class="pb-32">
            <h5>Intellectual Property</h5>
            <ol>
              <li>
                All Content included on the Service, unless uploaded by Users,
                including, but not limited to, text, graphics, logos, icons,
                images, sound clips, video clips, data compilations, page
                layout, underlying code and software is the property of idVPN,
                our affiliates or other relevant third parties. By continuing to
                use the Service you acknowledge that such material is protected
                by applicable Turkish and International intellectual property
                and other relevant laws.
              </li>
              <li>
                You may not reproduce, copy, distribute, store or in any other
                fashion re-use material from the Service unless otherwise
                indicated on the Service or unless given express written
                permission to do so by idVPN.
              </li>
              <li>
                You acknowledge that all intellectual property rights in idVPN
                anywhere in the world belong to us, that rights in idVPN are
                licensed (not sold) to you, and that you have no rights in, or
                to, idVPN other than the right to use them in accordance with
                these Terms.
              </li>
              <li>
                Any intellectual property rights in content uploaded by you to
                idVPN shall continue to belong to you or their respective
                owners. You agree that you grant us a royalty-free and
                non-exclusive license to use, reproduce, publish, and display
                such intellectual property rights for the purposes of performing
                the Services, promotional purposes, internal administrative
                purposes and any other purposes set out in these Terms,
                including for the purpose of improving the Services and our
                responses to users of the Service.
              </li>
              <li>
                You acknowledge that you have no right to have access to idVPN
                in source code form.
              </li>
              <li>
                You must not modify the paper or digital copies of any materials
                you have printed off or downloaded from idVPN in any way and you
                must not use any illustrations, photographs, video or audio
                sequences or any graphics separately from any accompanying text.
              </li>
              <li>
                You must not use any part of the content on idVPN for commercial
                purposes not specified on idVPN without obtaining a license to
                do so from us or our licensors.
              </li>
              <li>
                If you print off, copy, or download any content on idVPN in
                breach of this Agreement, your right to use idVPN will cease
                immediately, and you must, at our option, return or destroy any
                copies of the materials you have made.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Use of idVPN</h5>
            <ol>
              <li>
                The provisions set out in these Terms govern your access to and
                your use of idVPN and shall constitute a legally binding
                agreement between you and us. We may change such terms from time
                to time and shall notify you accordingly if we do. If you do not
                agree to such terms, you must not use idVPN.
              </li>
              <li>
                Subject to you agreeing to abide by these Terms, we hereby grant
                to you a revocable, non-exclusive, and non-transferable license
                to use idVPN on these Terms.
              </li>
              <li>
                We reserve the right to, without any notice, explanation, or
                liability and in our sole discretion, refuse to allow you or
                suspend your access to idVPN at any time, or remove or edit
                content on idVPN or on any of our affiliated websites (including
                social media pages).
              </li>
              <li>
                We reserve the right to change, modify, suspend or discontinue
                any portion of the Services, idVPN or any other products,
                services, and/or other software provided by us in connection
                with any of the foregoing at any time. You agree that access to
                or operation of any of the foregoing may from time to time be
                interrupted or encounter technical difficulties.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Nonexclusive license</h5>
            <ol>
              <li>
                idVPN grants you a limited, revocable, non-exclusive,
                non-sub-licensable license to install, copy and use the Service
                solely as necessary for you to use the Service on your
                Properties or Third Party's Properties; and
              </li>
              <li>
                You will not and you will not allow any third party to:
                <ol>
                  <li class="to-lower-alpha">
                    copy, modify, adapt, translate or otherwise create
                    derivative works of the App;
                  </li>
                  <li class="to-lower-alpha">
                    reverse engineer, decompile, disassemble or otherwise
                    attempt to discover the source code of the App, except as
                    expressly permitted by the law in effect in the jurisdiction
                    in which you are located;
                  </li>
                  <li class="to-lower-alpha">
                    rent, lease, sell, assign or otherwise transfer rights in or
                    to the Service;
                  </li>
                  <li class="to-lower-alpha">
                    remove any proprietary notices or labels on the Service or
                    placed by idVPN;
                  </li>
                  <li class="to-lower-alpha">
                    use, post, transmit or introduce any device, software or
                    routine which interferes or attempts to interfere with the
                    operation of the Service or idVPN; or
                  </li>
                  <li class="to-lower-alpha">
                    use data labelled as belonging to a third party in the
                    Service for purposes other than generating, viewing and
                    downloading Reports. You will comply with all applicable
                    laws and regulations in Your use of and access to idVPN and
                    Service.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Mobile Application License</h5>
            <ol>
              <li>
                If you access the Service via a mobile application, then we
                grant you a revocable, non-exclusive, non-transferable, limited
                right to install and use the App on wireless electronic devices
                owned or controlled by you, and to access and use the App on
                such devices strictly in accordance with the Terms of this App
                License contained in these Terms.
              </li>
              <li>
                You shall not:
                <ol>
                  <li class="to-lower-alpha">
                    decompile, reverse engineer, disassemble, attempt to derive
                    the source code of, or decrypt the App;
                  </li>
                  <li class="to-lower-alpha">
                    make any modification, adaptation, improvement, enhancement,
                    translation, or derivative work from the App;
                  </li>
                  <li class="to-lower-alpha">
                    violate any applicable laws, rules, or regulations in
                    connection with your access or use of the App;
                  </li>
                  <li class="to-lower-alpha">
                    remove, alter, or obscure any proprietary notice (including
                    any notice of copyright or trademark) posted by us or the
                    licensors of the App;
                  </li>
                  <li class="to-lower-alpha">
                    use the App for any revenue generating endeavour, commercial
                    enterprise, or other purpose for which it is not designed or
                    intended;
                  </li>
                  <li class="to-lower-alpha">
                    make the App available over a network or other environment
                    permitting access or use by multiple devices or users at the
                    same time;
                  </li>
                  <li class="to-lower-alpha">
                    use the App for creating a product, service, or software
                    that is, directly or indirectly, competitive with or in any
                    way a substitute for the App;
                  </li>
                  <li class="to-lower-alpha">
                    use the App to send automated queries to any Website or to
                    send any unsolicited commercial e-mail;
                  </li>
                  <li class="to-lower-alpha">
                    use any proprietary information or any of our interfaces or
                    our other intellectual property in the design, development,
                    manufacture, licensing, or distribution of any applications,
                    accessories, or devices for use with the App.
                  </li>
                </ol>
              </li>
              <li>
                The following terms apply when you access the App:
                <ol>
                  <li class="to-lower-alpha">
                    the License granted to you for our App is limited to a
                    non-transferable License to use the App on a device that
                    utilises the Apple iOS operating systems, and in accordance
                    with the usage rules set forth in the Apple iOS Terms;
                  </li>
                  <li class="to-lower-alpha">
                    we are responsible for providing any maintenance and support
                    services with respect to the App as specified in the Terms
                    of this App License contained in these Terms or as otherwise
                    required under applicable law, and you acknowledge that
                    Apple has no obligation whatsoever to furnish any
                    maintenance and support services with respect to the App;
                  </li>
                  <li class="to-lower-alpha">
                    in the event of any failure of the App to conform to any
                    applicable warranty, you may notify Apple, in accordance
                    with its terms and policies, may refund the purchase price,
                    if any, paid for if any, and to the maximum extent permitted
                    by applicable law, Apple will have no other warranty
                    obligation whatsoever with respect to the App;
                  </li>
                  <li class="to-lower-alpha">
                    you represent and warrant that (i) you are not located in a
                    country that is subject to a U.S. government embargo, or
                    that has been designated by the U.S. government as a
                    “terrorist supporting” country and (ii) you are not listed
                    on any U.S. government list of prohibited or restricted
                    parties;
                  </li>
                  <li class="to-lower-alpha">
                    you must comply with applicable third-party terms of
                    agreement when using the App, then you must not be in
                    violation of their wireless data service agreement when
                    using the App;
                  </li>
                  <li class="to-lower-alpha">
                    you acknowledge and agree that Apple is a third-party
                    beneficiary of the Terms in this App License contained in
                    these Terms, and that Apple will have the right (and will be
                    deemed to have accepted the right) to enforce the Terms in
                    this App License contained in these Terms against you as a
                    third-party beneficiary thereof.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Restrictions</h5>
            <ol>
              <li>
                Except as expressly set out in this Agreement or as permitted by
                any applicable law, you undertake:
                <ol>
                  <li class="to-lower-alpha">
                    not to reproduce, copy, modify, adapt, translate, publish,
                    display, communicate, transmit, sell, exploit or use the
                    whole or any part of any Service, idVPN or any of the
                    contents therein for any commercial or other purposes;
                  </li>
                  <li class="to-lower-alpha">
                    not to disassemble, decompile, reverse-engineer or create
                    derivative works based on the whole or any part of the
                    source code of idVPN nor attempt to do any such thing, or to
                    reproduce, display or otherwise provide access to the
                    Services, idVPN or any of the contents therein, including
                    but not limited to framing, mirroring, linking, spidering,
                    scraping or any other technological means;
                  </li>
                  <li class="to-lower-alpha">
                    not to provide or otherwise make available idVPN in whole or
                    in part (including but not limited to listings, object and
                    source listings, object code and source code), in any form
                    to any person without prior written consent from us;
                  </li>
                  <li class="to-lower-alpha">
                    to include our copyright notice on all entire and partial
                    copies you make of idVPN on any medium;
                  </li>
                  <li class="to-lower-alpha">
                    to comply with all applicable technology control or export
                    laws and regulations; and
                  </li>
                  <li class="to-lower-alpha">
                    not to disrupt, disable, or otherwise impair the proper
                    working of the Services, idVPN or our servers, such as
                    through hacking, cyber-attacks (including but not limited to
                    denial-of-service attacks), tampering or reprogramming.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Prohibited uses</h5>
            <ol>
              <li>
                You may use idVPN only for lawful purposes. You may not use
                idVPN:
                <ol>
                  <li class="to-lower-alpha">
                    in any way that breaches any applicable local or
                    international laws or regulations;
                  </li>
                  <li class="to-lower-alpha">
                    in any way that is unlawful or fraudulent, or has any
                    unlawful or fraudulent purpose or effect;
                  </li>
                  <li class="to-lower-alpha">
                    to knowingly transmit any data, send or upload any material
                    that contains viruses, Trojan horses, worms, time-bombs,
                    keystroke loggers, spyware, adware or any other harmful
                    programs or similar computer code designed to adversely
                    affect the operation of any computer software or hardware.
                  </li>
                </ol>
              </li>
              <li>
                You also agree:
                <ol>
                  <li class="to-lower-alpha">
                    not to reproduce, duplicate, copy or re-sell any part of
                    idVPN in contravention of the provisions of our Terms; and
                  </li>
                  <li class="to-lower-alpha">
                    not to access without authority, interfere with, damage or
                    disrupt;
                  </li>
                  <li class="to-lower-alpha">any part of idVPN;</li>
                  <li class="to-lower-alpha">
                    any equipment or network on which idVPN is stored;
                  </li>
                  <li class="to-lower-alpha">
                    any software used in the provision of idVPN; or
                  </li>
                  <li class="to-lower-alpha">
                    any equipment or network or software owned or used by any
                    third party.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Service Management</h5>
            <ol>
              <li>
                We reserve the right, but not the obligation, to:
                <ol>
                  <li class="to-lower-alpha">
                    monitor the Service for violations of these Terms;
                  </li>
                  <li class="to-lower-alpha">
                    take appropriate legal action against anyone who, in our
                    sole discretion, violates the law or these Terms, including
                    without limitation, reporting such user to law enforcement
                    authorities;
                  </li>
                  <li class="to-lower-alpha">
                    in our sole discretion and without limitation, refuse,
                    restrict access to, limit the availability of, or disable
                    (to the extent technologically feasible) any of your
                    Contributions or any portion thereof;
                  </li>
                  <li class="to-lower-alpha">
                    in our sole discretion and without limitation, notice, or
                    liability, to remove from the Service or otherwise disable
                    all files and content that are excessive in sise or are in
                    any way burdensome to our systems;
                  </li>
                  <li class="to-lower-alpha">
                    otherwise manage the Service in a manner designed to protect
                    our rights and property and to facilitate the proper
                    functioning of the Service.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Privacy</h5>
            <ol>
              <li>
                For the purposes of applicable data protection legislation,
                idVPN will process any personal data you have provided to us in
                accordance with our Privacy Policy.
              </li>
              <li>
                You agree that, if you have provided idVPN with personal data
                relating to a third party (a) you have in place all necessary
                appropriate consents and notices to enable lawful transfer such
                personal data to idVPN and (b) that you have brought to the
                attention of any such third party the Privacy Policy of idVPN.
                You agree to indemnify idVPN in relation to all and any
                liabilities, penalties, fines, awards or costs arising from your
                non-compliance with these requirements.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Modifications And Interruptions</h5>
            <ol>
              <li>
                We reserve the right to change, modify, or remove the contents
                of the Service at any time or for any reason at our sole
                discretion without notice. However, we have no obligation to
                update any information on idVPN. We also reserve the right to
                modify or discontinue all or part of the Service without notice
                at any time.
              </li>
              <li>
                We will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Service.
              </li>
              <li>
                We cannot guarantee the Service will be available at all times.
                We may experience hardware, software, or other problems or need
                to perform maintenance related to the Service, resulting in
                interruptions, delays, or errors.
              </li>
              <li>
                We reserve the right to change, revise, update, suspend,
                discontinue, or otherwise modify the Service at any time or for
                any reason without notice to you. You agree that we have no
                liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Service during any
                downtime or discontinuance of the Service.
              </li>
              <li>
                Nothing in these Terms will be construed to obligate us to
                maintain and support the Service or to supply any corrections,
                updates, or releases in connection therewith.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Corrections</h5>
            <p>
              There may be information on the Service that contains
              typographical errors, inaccuracies, or omissions that may relate
              to the Service, including descriptions, pricing, availability, and
              various other information. We reserve the right to correct any
              errors, inaccuracies, or omissions and to change or update the
              information on the Service at any time, without prior notice.
            </p>
          </li>

          <li class="pb-32">
            <h5>Availability of the Service</h5>
            <ol>
              <li>
                The Service is provided “as is” and on an “as available” basis.
                We give no warranty that the Service will be free of defects and
                / or faults. To the maximum extent permitted by the law we
                provide no warranties (express or implied) of fitness for a
                particular purpose, accuracy of information, compatibility, and
                satisfactory quality.
              </li>
              <li>
                idVPN accepts no liability for any disruption or
                non-availability of the Service resulting from external causes
                including, but not limited to, ISP equipment failure, host
                equipment failure, communications network failure, power
                failure, natural events, acts of war or legal restrictions and
                censorship.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Disclaimers</h5>
            <ol>
              <li>
                idVPN makes no warranty or representation that the Service will
                meet your requirements, that it will be of satisfactory quality,
                that it will be fit for a particular purpose, that it will not
                infringe the rights of third parties, that it will be compatible
                with all systems, that it will be secure, and that all
                information provided will be accurate. We make no guarantee of
                any specific results from the use of idVPN.
              </li>
              <li>
                No part of this Service is intended to constitute advice and the
                Content of this Service should not be relied upon when making
                any decisions or taking any action of any kind.
              </li>
              <li>
                The Service is provided on an as-is and as-available basis.
              </li>
              <li>
                You agree that your use of the Service and idVPN will be at your
                sole risk. To the fullest extent permitted by law, we disclaim
                all warranties, express or implied, in connection with the
                Service and your use thereof, including, without limitation, the
                implied warranties of merchantability, fitness for a particular
                purpose, and non-infringement. We make no warranties or
                representations about the accuracy or completeness of the
                Service’s content, or the content of any website linked to the
                Service, and we will assume no liability or responsibility for
                any
                <ol>
                  <li class="to-lower-alpha">
                    errors, mistakes, or inaccuracies of content and materials,
                  </li>
                  <li class="to-lower-alpha">
                    personal injury or property damage, of any nature
                    whatsoever, resulting from your access to and use of the
                    Service,
                  </li>
                  <li class="to-lower-alpha">
                    any unauthorised access to or use of our secure servers
                    and/or any and all personal information and/or financial
                    information stored therein,
                  </li>
                  <li class="to-lower-alpha">
                    any interruption or cessation of transmission to or from the
                    Service,
                  </li>
                  <li class="to-lower-alpha">
                    any bugs, viruses, trojan horses, or the like which may be
                    transmitted to or through the Service by any third party,
                    and/or
                  </li>
                  <li class="to-lower-alpha">
                    any errors or omissions in any content and materials or for
                    any loss or damage of any kind incurred as a result of the
                    use of any content posted, transmitted, or otherwise made
                    available via the Service.
                  </li>
                </ol>
              </li>
              <li>
                We do not warrant, endorse, guarantee, or assume responsibility
                for any product or service advertised or offered by a third
                party through the Service, any hyperlinked website, or any
                website or mobile application featured in any banner or other
                advertising, and we will not be a party to or in any way be
                responsible for monitoring any transaction between you and any
                third-party providers of products or services.
              </li>
              <li>
                As with the purchase of a product or service through any medium
                or in any environment, you should use your best judgment and
                exercise caution where appropriate.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>User Data</h5>
            <ol>
              <li>
                We do not collect logs of your activity, including no logging of
                browsing history, traffic destination, data content, or DNS
                queries.
              </li>
              <li>
                We also never store connection logs, meaning no logs of your IP
                address, your outgoing VPN IP address, connection timestamp, or
                session duration.
              </li>
              <li>
                We do not log your VPN sessions, browsing behaviour, the
                websites you visit, or any other activity related to your VPN
                connection. In addition, we never store VPN connection logs and
                timestamps that associate your incoming and outgoing IP address
                or session duration.
              </li>
              <li>
                We will maintain certain data that you transmit to the Service
                for the purpose of managing the Service, as well as data
                relating to your use of the Service. Although we perform regular
                routine backups of data, you are solely responsible for all data
                that you transmit or that relates to any activity you have
                undertaken using the Service.
              </li>
              <li>
                You agree that we shall have no liability to you for any loss or
                corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of
                such data.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Limitation of liability</h5>
            <ol>
              <li>
                We are not liable for the completeness, accuracy or correctness
                of any information uploaded to idVPN and any Related Content.
                You expressly agree that your use of the Services and idVPN,
                including reliance on any Consultant’s Advice, is at your sole
                risk.
              </li>
              <li>
                You agree not to use the Services, idVPN and the Related Content
                for any re-sale purposes, and we have no liability to you,
                whether in contract, tort (including negligence), breach of
                statutory duty, or otherwise, arising under or in connection
                with these Terms (including but not limited to the use of, or
                inability to use, the Services, idVPN or any other website or
                software) for:
                <ol>
                  <li class="to-lower-alpha">
                    loss of profits, sales, business, or revenue;
                  </li>
                  <li class="to-lower-alpha">business interruption;</li>
                  <li class="to-lower-alpha">loss of anticipated savings;</li>
                  <li class="to-lower-alpha">
                    loss or corruption of data or information;
                  </li>
                  <li class="to-lower-alpha">
                    loss of business opportunity, goodwill or reputation; or
                  </li>
                  <li class="to-lower-alpha">
                    any other indirect or consequential loss or damage.
                  </li>
                </ol>
              </li>
              <li>
                Nothing in these Terms shall limit or exclude our liability for:
                <ol>
                  <li class="to-lower-alpha">
                    death or personal injury resulting from our negligence;
                  </li>
                  <li class="to-lower-alpha">fraud; and/or</li>
                  <li class="to-lower-alpha">
                    any other matter in respect of which we are prohibited under
                    applicable law from limiting or excluding our liability.
                  </li>
                </ol>
              </li>
              <li>
                idVPN is not intended to serve a record-keeping function and we
                shall not be liable for any loss of data or content.
              </li>
              <li>
                These Terms set out the full extent of our obligations and
                liabilities in respect of the supply of the Services and idVPN.
                Except as expressly stated in these Terms, there are no
                conditions, warranties, representations or other terms, express
                or implied, that are binding on us. Any condition, warranty,
                representation or other term concerning the supply of the
                Services and idVPN which might otherwise be implied into, or
                incorporated in, these Terms whether by statute, common law or
                otherwise, is excluded to the fullest extent permitted by law.
              </li>
            </ol>
          </li>

          <li class="pb-32">
            <h5>Indemnity</h5>
            <p>
              You agree to indemnify and hold us, our related corporations, and
              our respective directors, officers, employees, agents and
              representatives, independent contractors, licensees, successors
              and assigns harmless from and against all claims, losses,
              expenses, damages and costs (including but not limited to direct,
              incidental, consequential, exemplary and indirect damages), and
              reasonable legal fees, resulting from or arising out of your act,
              default or omission, whether in your use of idVPN, Services,
              and/or any websites or software in relation thereto or otherwise,
              and whether in respect of your breach of these Terms or any laws
              or regulations or otherwise.
            </p>
          </li>

          <li class="pb-32">
            <h5>No Waiver</h5>
            <p>
              In the event that any party to these Terms fails to exercise any
              right or remedy contained herein, this shall not be construed as a
              waiver of that right or remedy.
            </p>
          </li>

          <li class="pb-32">
            <h5>Previous Terms</h5>
            <p>
              In the event of any conflict between these Terms and any prior
              versions thereof, the provisions of these Terms shall prevail
              unless it is expressly stated otherwise.
            </p>
          </li>

          <li class="pb-32">
            <h5>Severance</h5>
            <p>
              Each of the conditions of these Terms operates separately. If any
              court or competent authority decides that any of them are unlawful
              or unenforceable, the remaining conditions will remain in full
              force and effect.
            </p>
          </li>

          <li class="pb-32">
            <h5>Law and Jurisdiction</h5>
            <p>
              These Terms and the relationship between you and idVPN shall be
              governed by and construed in accordance with the Law of Turkey and
              idVPN and you agree to submit to the exclusive jurisdiction of the
              Courts of Turkey.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.primary-container {
  display: flex;
  justify-content: center;
  background-color: #fefdfa;
  padding: 64px;
  .container {
    max-width: 1312px;
    width: 100%;
    color: #222222;
    .first-container {
      padding-bottom: 64px;
      h1 {
        font-size: 64px;
        line-height: 84px;
        font-weight: 900;
        padding-bottom: 16px;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
      }
    }
    .second-container {
      p {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        padding-bottom: 32px;
      }
      p:last-child {
        padding-bottom: 0 !important;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
      }
      ol {
        li {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          margin-left: 64px;
          ol {
            counter-reset: alpha;
            li {
              counter-increment: alpha;
            }
            .to-lower-alpha {
              list-style-type: lower-alpha;
            }
            .to-lower-alpha::marker {
              content: counter(alpha, lower-alpha) ")\a0";
            }
          }
        }
        .pb-32 {
          margin-bottom: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .primary-container {
    padding: 32px 16px;
    .container {
      .first-container {
        padding-bottom: 32px;
        h1 {
          font-size: 36px;
          line-height: 48px;
          font-weight: 900;
          padding-bottom: 16px;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 900;
        }
      }
      .second-container {
        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding-bottom: 16px;
        }
        p:last-child {
          padding-bottom: 0 !important;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 900;
        }
        ol {
          li {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-left: 32px;
          }
          .pb-32 {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>
