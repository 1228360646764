<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "./components/UI/TheFooter.vue";
import TheHeader from "./components/UI/TheHeader.vue";

export default {
  components: { TheHeader, TheFooter },
  name: "App",
};
</script>

<style>
@import url("https://use.typekit.net/jel3hdy.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: work-sans, sans-serif;
}
</style>
