<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fefdfa;
    "
  >
    <div
      class="details-main-container"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="container-1">
        <div class="header-image">
          <img
            :src="
              'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
              getBlog?.headerImage +
              '?alt=media'
            "
          />
        </div>
        <div class="title">
          <div class="h-title">
            <h1>{{ getBlog.title }}</h1>
          </div>
        </div>

        <div class="datepart">
          <p>
            {{ (new Date(getBlog.createdAt.seconds * 1000) + "").slice(4, 15) }}
          </p>
        </div>
        <div class="part-2">
          <div class="content" v-html="getBlog?.description"></div>
        </div>
      </div>
    </div>
    <div
      class="main-container"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="container-1">
        <div class="blog-title">
          <div class="h-title" style="margin-bottom: 0 !important">
            <h1>Recent posts</h1>
          </div>
        </div>
        <div class="part-3">
          <div class="recent-blogs">
            <div
              v-for="(blog, index) in blogs.slice(-3)"
              :key="blog.id"
              :class="'postImage div' + (index + 1)"
            >
              <img
                :src="
                  'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
                  blog.postImage +
                  '?alt=media'
                "
              />
              <p>
                {{
                  (new Date(blog.createdAt.seconds * 1000) + "").slice(4, 15)
                }}
              </p>
              <router-link class="blog-link" :to="'/blog/' + blog.id"
                ><p>
                  {{ blog.title }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seventh-container">
      <div class="container">
        <div class="first-container">
          <h1>The one-click solution for your privacy needs</h1>
          <h5>
            Say no to internet restrictions and data thieves with an
            award-winning VPN app.
          </h5>
          <img src="../../assets/images/CTA.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blogsRef from "../firebase";
import { getDocs } from "firebase/firestore";
import "firebase/compat/firestore";
export default {
  data() {
    return {
      blogs: [],
      blog: {},
    };
  },
  methods: {
    async getBlogs() {
      let blogsSnapshot = await getDocs(blogsRef);
      let blogs = [];
      blogsSnapshot.forEach((blog) => {
        let blogData = blog.data();
        blogData.id = blog.id;
        if (blogData.product == "idVPN") blogs.push(blogData);
        // blogs.push(blogData);
      });
      this.blogs = blogs;
    },
  },

  created() {
    this.getBlogs();
  },
  computed: {
    getBlog() {
      return this.blogs.find((x) => x.id == this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-main-container {
  .container-1 {
    .part-2 {
      width: 100%;
      .content {
        /deep/ p {
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 32px;
          font-weight: 400;
          margin-bottom: 0;
        }
        /deep/ img {
          object-fit: cover;
          border-radius: 32px;
          margin: 64px 0;
          max-width: 100%;
          width: 100%;
          text-align: center;
          display: block;
        }
        /deep/ p:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        /deep/ ul {
          margin-bottom: 2rem;
          margin-left: 3.5rem;
          margin-right: 2rem;
          font-size: 24px;
          line-height: 32px;
          color: #252525;
          word-break: break-all;
        }
        /deep/ h1 {
          font-size: 64px;
          padding: 2rem 0;
        }
        /deep/ strong {
          display: block;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .details-main-container {
    .container-1 {
      .part-2 {
        width: 100%;

        .content {
          /deep/ p {
            // padding: 0 2rem;
            font-size: 24px;
            padding-bottom: 32px;
            line-height: 32px;
          }
          /deep/ img {
            object-fit: cover;
            border-radius: 32px;
            margin: 64px 0;
            max-width: 100%;
          }
          /deep/ p:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
          }

          /deep/ ul {
            margin-bottom: 2rem;
            margin-left: 3.5rem;
            margin-right: 2rem;
            font-size: 24px;
            line-height: 32px;
            color: #252525;
            word-break: break-all;
          }

          /deep/ h1 {
            font-size: 64px;
            padding: 2rem 0;
          }
        }
      }
    }
  }
}
// //
// ul {
//       padding-bottom: 2rem;
//       padding-left: 1.5rem;
//       padding-right: 2rem;
//       font-size: 24px;
//       line-height: 32px;
//       color: #252525;
//       word-break: break-all;
//     }
//

@media screen and (max-width: 1200px) {
  .details-main-container {
    .container-1 {
      .part-2 {
        width: 100%;

        .content {
          /deep/ p {
            //   padding: 0 2rem;
            font-size: 24px;
            padding-bottom: 32px;
            line-height: 32px;
          }
          /deep/ img {
            object-fit: cover;
            border-radius: 32px;
            margin: 64px 0;
            max-width: 100%;
          }
          /deep/ p:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
          }
          /deep/ ul {
            margin-bottom: 2rem;
            margin-left: 3.5rem;
            margin-right: 2rem;
            font-size: 24px;
            line-height: 32px;
            color: #252525;
            word-break: break-all;
          }

          /deep/ h1 {
            font-size: 64px;
            padding: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .details-main-container {
    .container-1 {
      .header-image {
        img {
          height: auto;
          width: 100%;
          max-width: 100%;
          max-height: unset;
          aspect-ratio: 1/1;
          border-radius: 32px;
        }
      }
      .part-2 {
        width: 100%;

        .content {
          /deep/ p {
            // padding: 0 1.5rem;
            font-size: 16px;
            line-height: 24px;

            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-bottom: 1rem;
            a {
              word-break: break-all;
            }

            ul {
              font-size: 16px;
              margin-left: 2.6rem;
              margin-right: 1.5rem;
              line-height: 24px;
              margin-bottom: 1.5rem;
            }
          }
          /deep/ img {
            margin: 32px 0;
            object-fit: cover;
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            max-width: 100%;
          }

          /deep/ p:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          /deep/ ul {
            font-size: 16px;
            margin-left: 2.6rem;
            margin-right: 1.5rem;
            line-height: 24px;
            margin-bottom: 1.5rem;
          }

          /deep/ h1 {
            padding: 1.5rem;
            font-size: 32px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .details-main-container {
    .container-1 {
      .header-image {
        img {
          height: unset;
          width: 100%;
          max-width: 100%;
          max-height: unset;
          aspect-ratio: 1/1;
          border-radius: 32px;
        }
      }
      .part-2 {
        width: 100% !important;
        .content {
          /deep/ img {
            max-width: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 32px;
            aspect-ratio: 1/1;
            // max-height: 256px;
          }
          /deep/ h1 {
            font-size: 32px;
            padding: 1.5rem;
          }
        }
      }
      .part-3 {
        width: calc(100% - 48px);
        .recent-blogs {
          img {
            // height: 256px !important;
            width: 100%;
            border-radius: 32px;
            max-width: 100%;
            // max-height: 256px !important;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }
}

.seventh-container {
  display: flex;
  justify-content: center;
  box-shadow: inset 0px 1px 0px 0px #222222;
  padding: 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #e9d974;
    box-shadow: 0px 0px 16px rgba(233, 217, 116, 0.64);
    border-radius: 32px;
    .first-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 64px;
      color: #222222;
      h1 {
        font-size: 64px;
        line-height: 84px;
        font-weight: 900;
        padding-bottom: 32px;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        padding-bottom: 64px;
      }
    }
    // .img-container {
    //   display: flex;
    //   align-items: center;
    //   margin: 32px;
    //   img {
    //     height: 96px;
    //     width: 96px;
    //   }
    // }
  }
}

@media screen and (max-width: 1024px) {
  .seventh-container {
    .container {
      .first-container {
        h1 {
          font-size: 48px;
          line-height: 64px;
        }
        h5 {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .seventh-container {
    padding: 32px 16px;
    .container {
      .first-container {
        padding: 32px 16px;
        h1 {
          font-size: 48px;
          line-height: 64px;
          font-weight: 900;
          padding-bottom: 16px;
        }
        h5 {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          padding-bottom: 32px;
        }
      }
    }
  }
}
@import "../../assets/styles/blog.scss";
</style>
