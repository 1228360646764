<template>
  <footer>
    <div class="container">
      <div class="first-container">
        <div class="title">
          <router-link
            class="main-title"
            style="
              text-decoration: none;
              color: #fff;
              display: inline-flex;
              width: 170px;
              align-items: center;
            "
            to="/"
          >
            <img src="../../assets/images/grain-effectCopy.svg" alt="" />
            <h1>idVPN</h1>
          </router-link>
        </div>
      </div>
      <div class="second-container">
        <p>
          Securely connect to your private network on any device with our
          easy-to-use apps. Twingate feels like magic because it quietly runs in
          the background and just works.
        </p>
        <div class="icons-container">
          <a
            href="https://www.facebook.com/idvpnapp/."
            target="_blank"
            aria-label="Facebook Page Of idVPN"
          >
            <img src="../../assets/images/logo-facebook.svg" alt="" />
          </a>
          <a
            href="https://www.instagram.com/idvpnapp/"
            target="_blank"
            aria-label="Instagram Page Of idVPN"
          >
            <img src="../../assets/images/logo-instagram.svg" alt="" />
          </a>
          <a
            href="https://twitter.com/idvpnapp/"
            target="_blank"
            aria-label="Twitter Page Of idVPN"
          >
            <img src="../../assets/images/logo-twitter.svg" alt="" />
          </a>
        </div>
        <h5>
          <router-link
            style="
              text-decoration: none;
              color: #ffffff;
              height: 24px;
              display: inline-block;
            "
            to="/privacy"
            >Privacy Policy</router-link
          >
          /
          <router-link
            style="
              text-decoration: none;
              color: #ffffff;
              height: 24px;
              display: inline-block;
            "
            to="/terms"
            >Terms & Conditions</router-link
          >
        </h5>
        <h6>© idVPN 2023</h6>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: center;
  background-color: #222222;
  color: #ffffff;
  padding: 64px;
  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .first-container {
      .title {
        display: flex;
        align-items: center;
        .short-title {
          display: none;
        }
        h1 {
          font-size: 24px;
          line-height: 32px;
          padding-left: 16px;
          font-weight: 900;
        }
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .second-container {
      padding-left: 518px;
      p {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 32px;
      }
      .icons-container {
        height: 24px;
        margin-bottom: 32px;
        a {
          padding: 0px 16px;
        }
        a:first-child {
          padding-left: 0px !important;
        }
        a:last-child {
          padding-right: 0px !important;
        }
      }
      h5 {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 32px;
        opacity: 0.9;
        font-weight: 400;
      }
      h6 {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.64;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  footer {
    .container {
      .second-container {
        padding-left: 438px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  footer {
    .container {
      .second-container {
        padding-left: 310px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    display: flex;
    justify-content: center;
    background-color: #222222;
    color: #ffffff;
    padding: 32px 16px;
    .container {
      display: flex;
      flex-direction: column;
      .second-container {
        padding-top: 32px;
        padding-left: 0;
        p {
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 16px;
        }
        .icons-container {
          margin-bottom: 16px;
          // img {
          //   padding: 0px 32px;
          // }
          // img:first-child {
          //   padding: 0px !important;
          // }
          // img:last-child {
          //   padding: 0px !important;
          // }
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 16px;
          font-weight: 400;
        }
        h6 {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
