<template>
  <div class="primary-container">
    <div class="container">
      <div class="first-container">
        <h1>Privacy Policy</h1>
        <h5>support@idvpn.app</h5>
      </div>
      <div class="second-container">
        <p>
          idVPN (Serhiy Ozhibko) of Knyahyni Olʹhy, L’viv, L’vivs’ka oblast,
          79000, Ukraine and email support@idvpn.app ("us", "we", or "our")
          operates the idVPN ("Service"). As a company that promises security,
          we recognise the importance of our customers fully understanding what
          personal data we collect, store and process. Our systems are designed
          with your privacy and the principle of data minimisation in mind.
        </p>
        <p>
          This privacy policy describes how we processes the personal data you
          provide to us in accordance with the requirements of the General Data
          Protection Regulation (GDPR).
        </p>
        <p>
          We have done our best to make this policy as clear and simple as
          possible so that you understand everything without having to wade
          through many pages of boring legal text.
        </p>
        <p>
          We do NOT log your VPN sessions, browsing behaviour, the websites you
          visit, or any other activity related to your VPN connection. In
          addition, we NEVER store VPN connection logs and timestamps that
          associate your incoming and outgoing IP address or session duration.
        </p>
        <h5>What does this policy cover?</h5>
        <p>
          This policy applies where we act as a data controller in relation to
          the personal data of visitors and service users; in other words, where
          we determine the purposes and means of processing that personal data.
          This includes when you use our services, when you use our iOS App.
        </p>
        <p>
          This policy does not apply to third-party services or products, even
          if we are associated with such third-party service or product.
        </p>
        <h5>Where this privacy policy applies</h5>
        <p>
          This privacy policy applies to the idVPN app. When the word “we” is
          used, it describes the controller (us) and includes the relevant
          service providers that help us to make the services available to you.
          When designing the app, we have made sure that no information that
          directly identifies you is collected.
        </p>
        <p>
          As however some countries including the EU have a broader definition
          of personal data this policy covers it. In this sense we would need to
          first of all explore the definition of personal data.
        </p>
        <p>
          "Personal data" means any information relating to an identified or
          identifiable natural person (hereinafter "data subject"); an
          identifiable natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as a
          name, an identification number, location data, an online identifier
          (e.g., cookie) or to one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity
          of that natural person.
        </p>
        <h5>What personal data do we collect and store?</h5>
        <p>
          If you contact us, your enquiry including all personal data resulting
          from it (name, email, message) will be stored and processed by us for
          the purpose of processing your request. We do not pass on this data
          without your consent. The processing of this data is based on Art. 6
          (1) lit. b GDPR if your request is related to the performance of a
          contract or is necessary for the implementation of pre-contractual
          measures. In all other cases, the processing is based on our
          legitimate interest in the effective processing of the enquiries
          addressed to us (Art. 6 para. 1 lit. f GDPR) or on your consent (Art.
          6 para. 1 lit. a GDPR) if this has been requested. The data you send
          us via contact requests will remain with us until you request us to
          delete it, revoke your consent to store it or the purpose for storing
          the data no longer applies. (e.g., after your request has been
          processed). Mandatory statutory provisions - in particular statutory
          retention periods - remain unaffected.
        </p>
        <p>
          When you send a data subject access request ,the legal basis for the
          processing of your personal data in the context of handling your data
          subject access request is our legal obligation and the legal basis for
          the subsequent documentation of the data subject access request is
          both our legitimate interest and our legal obligation (Art. 6 para. 1
          p. 1 lit. c. GDPR), and (Art. 6 para. 1 p. 1 lit. f GDPR). The purpose
          of processing your personal data in the context of processing data
          when you send a data subject access request is to respond to your
          request. The subsequent documentation of the data subject access
          request serves to fulfil the legally required accountability.
        </p>
        <h5>What information do we collect and store?</h5>
        <p>
          <i>a) When you log on to our VPN service</i><br />
          To protect your privacy, we only collect the minimum information
          necessary, and that is your userId to let you access our service. We
          store your userId encrypted on our secured servers.
        </p>
        <p>
          <i>b) When you connect to our VPN service</i><br />
          We keep a temporary log of connection data for troubleshooting
          purposes, which includes a randomly generated userId and is securely
          deleted every few hours. This randomly generated userId cannot be
          linked to you. We have deliberately and consistently chosen not to log
          all other data in order to limit our legal liability. We do, however,
          log the Connection status, bandwidth consumption so that we can bill
          you properly and maintain a high quality of service. All usage data is
          anonymised and not linked to your real, public IP address.
        </p>
        <p>
          <i>c) Device information</i><br />
          We collect information from and about your device(s), including
          hardware and software information, device ID and type, version and
          language, operating system, time sones, identifiers associated with
          cookies or other technologies that may uniquely identify your device,
          information on your wireless and mobile network connection, like your
          service provider and signal strength to maintain a record of how many
          devices you use to connect to our service.
        </p>
        <p>
          <i>d) Authorisations and Access</i><br />
          We may request access or permission to certain functions from your
          device (VPN configuration and push notifications.). The legal basis
          for data processing is our legitimate interest (Art. 6 para. 1 p. 1
          lit. f GDPR) and the provision of contractual or pre-contractual
          measures (Art. 6 para. 1 p. 1 lit. b GDPR).
        </p>
        <h5>Retention</h5>
        <p>
          Your personal data will be deleted as soon as they are no longer
          required to achieve the purpose for which they were collected. In the
          case of the processing of a data subject access request, this is three
          years after the end of the respective process. You have the
          possibility at any time to object to the processing of your personal
          data in the context of the processing of a data subject access request
          for the future. In this case, however, we will not be able to further
          process your request. The documentation of the legally compliant
          processing of the respective data subject access request is mandatory.
          Consequently, there is no possibility for you to object.
        </p>
        <h5>Security measures</h5>
        <p>
          We take appropriate technical and organisational measures in
          accordance with Article 32 of the GDPR, taking into account the state
          of the art, the costs of implementation and the nature, scope,
          circumstances and purposes of the processing, as well as the varying
          likelihood and severity of the risk to the rights and freedoms of
          natural persons, in order to ensure a level of protection appropriate
          to the risk; the measures include, in particular, safeguarding the
          confidentiality, integrity and availability of data by controlling
          physical access to the data, as well as access to, input of,
          disclosure of, assurance of availability of, and separation of, the
          data relating to them. We also have procedures in place to ensure the
          exercise of data subjects' rights, deletion of data and response to
          data compromise.
        </p>
        <p>
          Furthermore, we already take the protection of personal data into
          account during the development and selection of hardware, software,
          and procedures, in accordance with the principle of data protection
          through technology design and through data protection-friendly default
          settings (Art. 25 GDPR). The security measures include in particular
          the encrypted transmission of data between your device and our server
          and the connection to our service is protected by industry standard
          TLS (transport layout security).
        </p>
        <h5>Cooperation with processors and third parties</h5>
        <p>
          If, in the course of our processing, we disclose data to other persons
          and companies (order processors or third parties), transmit it to them
          or otherwise grant them access to the data, this will only be done on
          the basis of a legal permission (e.g., if a transmission of the data
          to third parties, such as to payment service providers, is necessary
          for the performance of the contract pursuant to Art. 6 para. 1 lit. b
          GDPR), you have consented, a legal obligation provides for this or on
          the basis of our legitimate interests (e.g., when using agents, web
          hosts, etc.). If we commission third parties to process data on the
          basis of a so-called "processing agreement", this is done on the basis
          of Art. 28 GDPR.
        </p>
        <p>
          <i>a) APP installation via the Apple App Store</i><br />
          You can use the Apple service "App Store" a service of Apple Inc, to
          install our app.
        </p>
        <p>
          As far as we are aware, Apple collects and processes the following
          data;
        </p>
        <ul>
          <li>device identifiers</li>
          <li>IP addresses</li>
          <li>location information</li>
        </ul>
        <br />
        <br class="web-break" />
        <p>
          It cannot be excluded that Apple also transmits the information to a
          server in a third country. This could in particular be Apple Inc. One
          Apple Park Way, Cupertino, California, USA, 95014. We cannot influence
          which personal data Apple processes with your registration and the
          provision of downloads in the respective app store and app store
          software. The responsible party in this respect is solely Apple as the
          operator of the Apple App Store. You can find more detailed
          information in Apple's privacy policy, which you can access here:
          https://www.apple.com/legal/privacy/.
        </p>
        <p>
          <i>b) Firebase</i><br />
          The App uses the Firebase tool, which is part of the Firebase platform
          of Google Inc, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA,
          to obtain statistics on how the App is used, in particular active user
          numbers, session length, stability rating and storage time. Answers
          logs the use of the app, and we evaluate user behaviour and user
          activity in general, i.e., not on a personal basis.
        </p>
        <p>
          For this purpose, the following data is transferred to the Analytics
          Engine: name and AppStore ID, build version, individual device
          installation key, timestamp, device model, device name, device
          operating system name and version numbers, the language and country
          settings of the device, the number of CPU cores on the device, whether
          a device has the status "jailbreak", app lifecycle events;
        </p>
        <p>
          The legal basis for this data processing is our legitimate interest.
          The data collected via Google will be deleted after 6 months at the
          latest. You can select in the settings under data services whether or
          not you want to send data to Google.
        </p>
        <h5>Who is the recipient of data? To whom is your data disclosed?</h5>
        <p>
          Data is only disclosed to third parties if there is a legal basis for
          the processing. For example, we disclose personal data to persons or
          companies that act as processors for us in accordance with Art. 28 of
          the GDPR. A processor is anyone who processes personal data on our
          behalf, i.e., in particular in an instruction and control relationship
          with us. In accordance with the requirements of the GDPR, we conclude
          a contract with each of our processors to oblige them to comply with
          data protection regulations and thus to provide your data with
          comprehensive protection.
        </p>
        <h5>Transfers to third countries</h5>
        <p>
          If processing takes place outside the EEA this is done in the context
          of using third-party services or disclosing or transferring data to
          third parties, this is only done if it is done in order to fulfil our
          (pre-) contractual obligations, on the basis of your consent, due to a
          legal obligation or on the basis of our legitimate interests. Subject
          to legal or contractual permissions, we only process or allow the
          processing of data in a third country if the special requirements of
          Art. 44 ff. GDPR are met. This means, for example, that processing is
          carried out on the basis of special guarantees, such as the officially
          recognised determination of a level of data protection corresponding
          to the EU or compliance with officially recognised special contractual
          obligations (so-called "standard contractual clauses").
        </p>
        <h5>Rights of data subjects</h5>
        <p>
          In accordance with the GDPR you have individual data subject right.
        </p>
        <ul>
          <li>
            You have the right to request confirmation as to whether data in
            question is being processed and to information about this data, as
            well as further information and a copy of the data in accordance
            with Art. 15 of the GDPR.
          </li>
          <li>
            You have according to. Article 16 of the GDPR, you have the right to
            request that the data concerning you be completed or that inaccurate
            data concerning you be corrected.
          </li>
          <li>
            In accordance with Art. 17 of the GDPR, you have the right to demand
            that the data in question be deleted without delay, or
            alternatively, in accordance with Art. 18 of the GDPR, to demand
            restriction of the processing of the data.
          </li>
          <li>
            You have the right to obtain the data concerning you that you have
            provided to us in accordance with Article 20 of the GDPR and to
            request that it be transferred to other data controllers.
          </li>
          <li>
            You also have the right to lodge a complaint with the competent
            supervisory authority in accordance with Art. 77 GDPR.
          </li>
          <li>
            You have the right to revoke any consent you have given in
            accordance with Art. 7 (3) of the GDPR with effect for the future.
          </li>
          <li>
            You may object to the future processing of data relating to you in
            accordance with Art. 21 GDPR at any time. The objection can be made
            in particular against the processing for purposes of direct
            advertising.
          </li>
        </ul>
        <br />
        <br class="web-break" />
        <h5>Data Subject Access Request</h5>
        <p>
          For clarification, you have the right to request confirmation from us
          at any time as to what information we hold about you and to request
          that we amend, update, or delete that information. We may comply with
          your request in response. In addition, we have the following options:
          Ask you to confirm your identity, or ask you for more information
          about your request, and were permitted by law, refuse your request.
          (However, in this case we will explain the reasons for the refusal).
        </p>

        <h5>Deletion of data</h5>
        <p>
          The data processed by us will be deleted or its processing restricted
          in accordance with Articles 17 and 18 of the GDPR. Unless expressly
          stated within the scope of this privacy policy, the data stored by us
          will be deleted as soon as it is no longer required for its intended
          purpose and the deletion does not conflict with any statutory
          retention obligations. If the data is not deleted because it is
          required for other and legally permissible purposes, its processing
          will be restricted. I.e., the data is blocked and not processed for
          other purposes. This applies, for example, to data that must be
          retained for reasons of commercial or tax law.
        </p>

        <h5>Automated decision-making</h5>
        <p>We do not use automated decision-making or profiling.</p>

        <h5>Do Not Sell My Personal data</h5>
        <p>
          We do not sell information that directly identifies you, like your
          name, address or phone records.
        </p>

        <h5>Accuracy</h5>
        <p>
          It is important that the data we hold about you is accurate and
          current, therefore please keep us informed of any changes to your
          personal data.
        </p>

        <h5>Revocation of your consent to data processing</h5>
        <p>
          Many data processing operations are only possible with your express
          consent. You can revoke consent you have already given at any time.
          The legality of the data processing carried out until the revocation
          remains unaffected by the revocation.
        </p>

        <h5>Information, deletion, and correction</h5>
        <p>
          Within the framework of the applicable legal provisions, you have the
          right at any time to free information about your stored personal data,
          its origin and recipient and the purpose of the data processing and,
          if applicable, the right to correction or deletion of this data. You
          can contact us at any time with regard to this and any other questions
          you may have on the subject of personal data.
        </p>

        <h5>Changes</h5>
        <p>
          This policy and our commitment to protecting the privacy of your
          personal data can result in changes to this policy. Please regularly
          review this policy to keep up to date with any changes.
        </p>
        <h5>Queries and Complaints</h5>
        <p>If you have any questions, please do not hesitate to contact us.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.primary-container {
  background-color: #fefdfa;
  display: flex;
  justify-content: center;
  padding: 64px;
  .container {
    max-width: 1312px;
    width: 100%;
    color: #222222;
    .first-container {
      padding-bottom: 64px;
      h1 {
        font-size: 64px;
        line-height: 84px;
        font-weight: 900;
        padding-bottom: 16px;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
      }
    }
    .second-container {
      p {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        padding-bottom: 32px;
      }
      p:last-child {
        padding-bottom: 0 !important;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
      }
      ul {
        li {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          margin-left: 64px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .primary-container {
    padding: 32px 16px;
    .container {
      .first-container {
        padding-bottom: 32px;
        h1 {
          font-size: 36px;
          line-height: 48px;
          font-weight: 900;
          padding-bottom: 16px;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 900;
        }
      }
      .second-container {
        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding-bottom: 16px;
        }
        p:last-child {
          padding-bottom: 0 !important;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 900;
        }
        ul {
          li {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-left: 32px;
          }
        }
        .web-break {
          display: none;
        }
      }
    }
  }
}
</style>
