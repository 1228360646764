<template>
  <div class="main" style="display: flex; flex-direction: column">
    <div class="f-container">
      <div class="container">
        <div class="first-container">
          <div class="text-con">
            <h1>The one-click solution for your privacy needs</h1>
            <h5>
              Say no to internet restrictions and data thieves with an
              award-winning VPN app.
            </h5>
          </div>
          <a
            href="https://apps.apple.com/us/app/idvpn-vpn-fast-secure/id1634503511"
          >
            <img src="../../assets/images/CTA.svg" alt="Cal To Action Button" />
          </a>
        </div>
        <div class="second-container">
          <img src="../../assets/images/Ill01.png" alt="" />
        </div>
      </div>
    </div>

    <div class="s-container">
      <div class="container">
        <div class="first-container">
          <h1>The only VPN you'll ever need</h1>
          <h5>
            idVPN encrypts your online traffic and hides your real IP address,
            whether you're streaming, aming, shopping, or simply browsing.
          </h5>
        </div>
        <div class="img-container">
          <picture class="img-container__picture">
            <source
              type="image/svg+xml"
              srcset="../../assets/screenshots/app-home-view.svg"
            />
            <img
              src="../../assets/screenshots/app-home-view.png"
              srcset="
                ../../assets/screenshots/app-home-view.png,
                ../../assets/screenshots/app-home-view-2x.png 2x,
                ../../assets/screenshots/app-home-view-3x.png 3x
              "
              alt=""
            />
          </picture>
        </div>
      </div>
    </div>

    <div class="th-container">
      <div class="container">
        <div class="first-container">
          <div class="inside-first">
            <img src="../../assets/images/Ill03.png" alt="" />
            <h1>Strict no-logs policy</h1>
            <h5>
              We've created an app that adds value to your online experience,
              having your security concerns in mind. Your data is safe with our
              AES-256 encryption and cutting-edge protocols.
            </h5>
          </div>
        </div>
        <div class="second-container">
          <div class="inside-second">
            <img src="../../assets/images/Ill04.png" alt="" />
            <h1>Top-tier encryption</h1>
            <h5>
              We use the IKEv2 protocol when you log on with idVPN. This allows
              the app to ensure a secure and high-speed connection. We have an
              extra layer of protection if you can't even trust yourself.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="fth-container">
      <div class="container">
        <div class="img-container">
          <picture class="img-container__picture">
            <source
              srcset="../../assets/screenshots/change-location-view.svg"
              type="image/svg+xml"
            />
            <img
              src="../../assets/screenshots/change-location-view.png"
              srcset="
                ../../assets/screenshots/change-location-view.png,
                ../../assets/screenshots/change-location-view-2x.png 2x,
                ../../assets/screenshots/change-location-view-3x.png 3x
              "
              alt=""
            />
          </picture>
        </div>
        <div class="first-container">
          <h1>Finally, a VPN you can trust</h1>
          <h5>
            idVPN helps you access any content, secure your online interactions
            and bring your internet life to a whole new level with just one tap.
          </h5>
        </div>
      </div>
    </div>

    <div class="fith-container">
      <div class="container">
        <div class="img-container">
          <div class="image">
            <picture>
              <source
                type="image/svg"
                srcset="../../assets/images/qr-code.svg"
              />
              <img src="../../assets/images/qr-code.png" alt="" />
            </picture>
          </div>
        </div>
        <div class="first-container">
          <h1>Unlock the internet's full potential with our VPN app!</h1>
        </div>
      </div>
    </div>

    <div class="eighth-container">
      <div class="container">
        <div class="first-container">
          <div class="xample">
            <img src="../../assets/images/Bg03.svg" alt="" />
          </div>
          <div class="text-con">
            <p>
              I love this app! It helps me bypass Wi-Fi that has restrictions.
              They have ads, but you can upgrade to premium to get rid of them.
            </p>
            <p>@SylviaR</p>
          </div>
        </div>
        <div class="second-container">
          <div class="xample">
            <img src="../../assets/images/Bg02.svg" alt="" />
          </div>
          <div class="text-con">
            <p>
              So far, so good. This one was recommended to me as the best free
              VPN.
            </p>
            <p>@AidenJ</p>
          </div>
        </div>
        <div class="third-container">
          <div class="xample">
            <img src="../../assets/images/Bg01.svg" alt="" />
          </div>
          <div class="text-con">
            <p>
              Installation is quick and painless, with no additional
              configuration to worry. Highly recommend!
            </p>
            <p>@RosaC</p>
          </div>
        </div>
        <div class="fourth-container">
          <div class="xample">
            <img src="../../assets/images/Bg04.svg" alt="" />
          </div>
          <div class="text-con">
            <p>
              I'm currently using the free version, and I could stream a few
              movies in HD back to back with no interruptions.
            </p>
            <p>@GeraldT</p>
          </div>
        </div>
        <div class="empty-container"></div>
      </div>
    </div>

    <div class="sixth-container">
      <div class="container">
        <div class="first-container">
          <h1>Frequently asked questions</h1>
        </div>
        <div class="second-container">
          <div
            class="toggle-list"
            :class="openFirst ? 'pb-16' : 'add-border pb-32'"
            @click="openFirst = !openFirst"
          >
            <h5>How does idVPN work?</h5>
            <span
              ><img
                :src="
                  require('../../assets/images/chevron-' +
                    (openFirst ? 'up' : 'down') +
                    '.svg')
                "
                alt=""
            /></span>
          </div>
          <div class="list-content" v-if="openFirst">
            <p>
              In a nutshell, idVPN creates an encrypted connection between your
              device and the Internet. This happens as soon as you connect to
              the idVPN server.<br /><br />We have thousands of servers
              worldwide, allowing you to change your virtual location. You can
              access streaming services, unblock social media platforms, bypass
              office, and school network restrictions, and even shop for better
              online deals!<br /><br />If you are new to VPN, we have customer
              support to help you with any questions.
            </p>
          </div>

          <div
            class="toggle-list"
            :class="openSecond ? 'pb-16' : 'add-border pb-32'"
            @click="openSecond = !openSecond"
          >
            <h5>Is idVPN secure?</h5>
            <span
              ><img
                :src="
                  require('../../assets/images/chevron-' +
                    (openSecond ? 'up' : 'down') +
                    '.svg')
                "
                alt=""
            /></span>
          </div>
          <div class="list-content" v-if="openSecond">
            <p>
              idVPN uses unbreakable IKEv2 encryption to protect your data. This
              prevents cybercriminals, governments, ISP, advertisers, and third
              parties from tracking your online activity. The US government uses
              the same encryption standard to protect classified information.<br />
              <br />
              An automatic kill switch keeps your current IP address hidden even
              if your internet connection is interrupted. In addition, our apps
              have built-in DNS leak protection and automatic Wi-Fi protection,
              so you can safely use the hotspot in airport waiting rooms,
              hotels, libraries, and cafes.
            </p>
          </div>

          <div
            class="toggle-list"
            :class="openThird ? 'pb-16' : 'add-border pb-32'"
            @click="openThird = !openThird"
          >
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <h5>Will idVPN slow down my internet connection?</h5>
              <div style="height: 24px">
                <img
                  :src="
                    require('../../assets/images/chevron-' +
                      (openThird ? 'up' : 'down') +
                      '.svg')
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="list-content" v-if="openThird">
            <p>
              We'll be honest: there's always a slight drop in speed when
              connecting to a VPN due to the encryption process. Any VPN company
              that tells you otherwise is not telling you the truth.<br /><br />
              The good news is that we designed idVPN specifically to prevent
              speed loss. Our servers can easily handle 4K streaming without
              buffering or lag. We've also invested in the latest hardware and
              best-in-class protocol (IKEv2), so you can enjoy lightning-fast
              connections.
            </p>
          </div>

          <div
            class="toggle-list"
            :class="openFourth ? 'pb-16' : 'add-border pb-32'"
            @click="openFourth = !openFourth"
          >
            <h5>Is idVPN free?</h5>
            <span
              ><img
                :src="
                  require('../../assets/images/chevron-' +
                    (openFourth ? 'up' : 'down') +
                    '.svg')
                "
                alt=""
            /></span>
          </div>
          <div class="list-content" v-if="openFourth">
            <p>
              idVPN is free to use. We also offer a 30-day money-back guarantee
              Pro plan. This gives you plenty of time to try each feature
              without risk to yourself. If you change your mind, you can get a
              full refund. No questions.
            </p>
          </div>

          <div
            class="toggle-list"
            :class="openFifth ? 'pb-16' : 'add-border pb-32'"
            @click="openFifth = !openFifth"
          >
            <h5>Is it legal to use a VPN?</h5>
            <span
              ><img
                :src="
                  require('../../assets/images/chevron-' +
                    (openFifth ? 'up' : 'down') +
                    '.svg')
                "
                alt=""
            /></span>
          </div>
          <div class="list-content" v-if="openFifth">
            <p>
              Yes, in most parts of the world. Individuals, governments, and
              corporations use VPNs to safeguard sensitive information and
              thwart cyber attacks.<br /><br />
              However, in countries with highly restrictive internet laws, you
              can only use government-approved VPNs. This includes China,
              Russia, Iran, and Egypt.<br /><br />
              Note: We do not condone using idVPN to break the law. Illegal
              activity is still illegal, even if you're under the protection of
              a VPN.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="seventh-container">
      <div class="container">
        <div class="first-container">
          <h1>The one-click solution for your privacy needs</h1>
          <h5>
            Say no to internet restrictions and data thieves with an
            award-winning VPN app.
          </h5>
          <a
            href="https://apps.apple.com/us/app/idvpn-vpn-fast-secure/id1634503511"
          >
            <img
              src="../../assets/images/CTA.svg"
              alt="Call To Action button"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      openFirst: false,
      openSecond: false,
      openThird: false,
      openFourth: false,
      openFifth: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import url("https://use.typekit.net/jel3hdy.css");
.f-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #fefdfa;

    .first-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 32px;
      background-color: #fefdfa;
      margin: 74px 0;
      .text-con {
        color: #222222;
        h1 {
          font-size: 64px;
          line-height: 84px;
          font-weight: 900;
        }
        h5 {
          font-size: 24px;
          line-height: 32px;
          padding: 32px 0px 64px 0px;
          font-weight: 400;
        }
      }
    }
    .second-container {
      width: 50%;
      background-color: #fefdfa;
      display: flex;
      padding: 0 0 0 32px;
      align-items: center;
      img {
        object-fit: contain;
        height: auto;
        width: 100%;
      }
    }
  }
}

.s-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #222222;
    border-radius: 32px;
    .first-container {
      padding-left: 64px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-size: 48px;
        line-height: 64px;
        padding-bottom: 32px;
        font-weight: 900;
      }
      h5 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
      }
    }
    .img-container {
      display: flex;
      align-items: flex-end;
      padding: 64px 64px 0 64px;

      &__picture {
        display: flex;
      }
    }
  }
}

.th-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #fefdfa;
    .first-container {
      color: #222222;
      background-color: #fff;
      border-radius: 32px;
      box-shadow: inset 0px 0px 0px 1px #222222;
      margin-right: 16px;
      width: 50%;
      .inside-first {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 32px;
        h1 {
          font-size: 24px;
          line-height: 32px;
          padding-top: 32px;
          font-weight: 900;
        }
        h5 {
          padding-top: 16px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .second-container {
      color: #222222;
      background-color: #fff;
      border-radius: 32px;
      box-shadow: inset 0px 0px 0px 1px #222222;
      margin-left: 16px;
      width: 50%;
      .inside-second {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 32px;
        h1 {
          font-size: 24px;
          line-height: 32px;
          padding-top: 32px;
          font-weight: 900;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding-top: 16px;
        }
      }
    }
  }
}

.fth-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #222222;
    border-radius: 32px;
    .first-container {
      padding-right: 64px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-size: 48px;
        line-height: 64px;
        padding-bottom: 32px;
        font-weight: 900;
      }
      h5 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
      }
    }
    .img-container {
      display: flex;
      align-items: flex-end;
      padding: 0 64px 0 64px;
      padding: 64px 64px 0 64px;

      &__picture {
        display: flex;
      }
    }
  }
}

.fith-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #e9d974;
    box-shadow: 0px 0px 16px rgba(233, 217, 116, 0.64);
    border-radius: 32px;
    .first-container {
      padding: 56px 32px 56px 0px;
      color: #222222;
      h1 {
        font-size: 32px;
        line-height: 48px;
        font-weight: 900;
      }
    }
    .img-container {
      display: flex;
      align-items: center;
      padding: 32px;
      .image {
        height: 96px;
        width: 96px;
        border-radius: 16px;
        background-color: #fefdfa;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img,
      picture {
        height: 64px;
        width: 64px;
      }
    }
  }
}

.sixth-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  box-shadow: inset 0px 1px 0px 0px #222222;
  .container {
    width: 100%;
    display: flex;
    background-color: #fefdfa;
    .first-container {
      width: 50%;
      margin-bottom: 212px;
      margin-right: 64px;
      color: #222222;
      background-color: #fefdfa;
      h1 {
        font-size: 64px;
        line-height: 84px;
        font-weight: 900;
      }
    }
    .second-container {
      width: 50%;
      background-color: #fefdfa;
      .add-border {
        border-bottom: 1px solid #222222;
      }
      .pb-16 {
        padding-bottom: 16px;
      }
      .pb-32 {
        padding-bottom: 32px;
      }
      .toggle-list {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #222222;
        padding-top: 32px;
        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 900;
        }
        span {
          height: 24px;
          img {
            height: 24px;
            width: 24px;
          }
        }
      }
      .list-content {
        border-bottom: 1px solid #222222;
        padding-bottom: 32px;
        p {
          color: rgba(34, 34, 34, 1);
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 24px;
        }
      }
      .list-content:last-child {
        border-bottom: none;
        padding-bottom: 0px !important;
      }
      .toggle-list:first-child {
        padding-top: 0px !important;
      }
      .toggle-list:last-child {
        border-bottom: none;
        padding-bottom: 0px !important;
      }
    }
  }
}

.seventh-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #222222;
  padding: 64px 64px 63px 64px;
  background-color: #fefdfa;
  .container {
    width: 100%;
    display: flex;
    background-color: #e9d974;
    box-shadow: 0px 0px 16px rgba(233, 217, 116, 0.64);
    border-radius: 32px;
    .first-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 64px;
      color: #222222;
      h1 {
        font-size: 64px;
        line-height: 84px;
        font-weight: 900;
        padding-bottom: 32px;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        padding-bottom: 64px;
      }
    }
    // .img-container {
    //   display: flex;
    //   align-items: center;
    //   margin: 32px;
    //   img {
    //     height: 96px;
    //     width: 96px;
    //   }
    // }
  }
}

.eighth-container {
  background-color: #fefdfa;
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // border-bottom: 1px solid #222222;
    padding: 64px 0 64px 0;
    margin-left: 64px;
    // margin-right: 64px;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #fefdfa;
    .first-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 16px;
      border-radius: 32px;
      width: 33.333%;
      box-shadow: inset 0px 0px 0px 1px #222222;
      background-color: #fff;
      .xample {
        display: flex;
        justify-content: center;
        padding: 64px 176px;
        img {
        }
      }

      .text-con {
        padding: 0px 32px 64px 32px;
        p {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900;
          text-align: center;
        }
        p:first-child {
          padding-bottom: 32px;
        }
      }
    }
    .second-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 16px;
      border-radius: 32px;
      width: 33.333%;
      box-shadow: inset 0px 0px 0px 1px #222222;
      background-color: #fff;
      .xample {
        display: flex;
        justify-content: center;
        padding: 64px 176px;
        img {
        }
      }
      .text-con {
        padding: 0px 32px 64px 32px;
        p {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900;
          text-align: center;
        }
        p:first-child {
          padding-bottom: 32px;
        }
      }
    }
    .third-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 16px;
      width: 33.333%;
      border-radius: 32px;
      box-shadow: inset 0px 0px 0px 1px #222222;
      background-color: #fff;
      .xample {
        display: flex;
        justify-content: center;
        padding: 64px 176px;
        img {
        }
      }
      .text-con {
        padding: 0px 32px 64px 32px;
        p {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900;
          text-align: center;
        }
        p:first-child {
          padding-bottom: 32px;
        }
      }
    }

    .fourth-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 16px;
      width: 33.333%;
      border-radius: 32px;
      box-shadow: inset 0px 0px 0px 1px #222222;
      background-color: #fff;
      .xample {
        display: flex;
        justify-content: center;
        padding: 64px 176px;
      }
      .text-con {
        padding: 0px 32px 64px 32px;
        p {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900;
          text-align: center;
        }
        p:first-child {
          padding-bottom: 32px;
        }
      }
    }

    .empty-container {
      padding: 0 32px;
    }
  }
}
.container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1280px) {
  .f-container {
    .container {
      .first-container {
        margin: 34px 0;
        // width: 544px;
      }
      // .second-container {
      //   img {
      //     height: 97.7021%;
      //   }
      // }
    }
  }

  .s-container {
    .container {
      flex-direction: column;
      .first-container {
        padding: 64px;
      }
      .img-container {
        padding: 0;
        justify-content: center;
        img {
          width: 375px;
          height: 406px;
        }
      }
    }
  }

  .fth-container {
    .container {
      flex-direction: column-reverse;
      .first-container {
        padding: 64px;
      }
      .img-container {
        padding: 0;
        justify-content: center;
        // padding: 0;
        img {
          width: 375px;
          height: 406px;
        }
      }
    }
  }

  .fith-container {
    .container {
      .first-container {
        padding: 32px 32px 32px 0;
      }
      .img-container {
        padding: 32px;
      }
    }
  }

  .eighth-container {
    .container {
      .first-container {
        width: 37%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }
      .second-container {
        width: 37%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }
      .third-container {
        width: 37%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }

      .fourth-container {
        width: 37%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .f-container {
    padding: 68px 64px 63px 64px;
    .container {
      flex-direction: column;
      .first-container {
        margin: 0;
        width: 100%;
        padding-right: 0;
        .text-con {
          h1 {
            font-size: 48px;
            line-height: 64px;
          }
          h5 {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
      .second-container {
        width: 100%;
        padding: 64px 0 0 0;

        // img {
        //   // height: 875.4px;
        //   // width: 896px;
        //   // height: auto;
        //   // width: 100%;
        //   // margin: 10.3px 0;
        // }
      }
    }
  }

  .s-container {
    .container {
      flex-direction: column;
      .first-container {
        padding: 64px 64px;
        h1 {
          font-size: 32px;
          line-height: 48px;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .img-container {
        padding: 0;
        justify-content: center;
        img {
          width: 375px;
          height: 406px;
        }
      }
    }
  }

  .th-container {
    .container {
      .first-container {
        .inside-first {
          h1 {
            font-size: 24px;
            line-height: 32px;
          }
          h5 {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .second-container {
        .inside-second {
          h1 {
            font-size: 24px;
            line-height: 32px;
          }
          h5 {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .fth-container {
    .container {
      flex-direction: column-reverse;
      .first-container {
        padding: 64px;
        h1 {
          font-size: 32px;
          line-height: 48px;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .img-container {
        padding: 0;
        justify-content: center;
        img {
          width: 375px;
          height: 406px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .fith-container {
    .container {
      .first-container {
        padding: 32px 32px 32px 0;
        // margin: 0;
        h1 {
          font-size: 24px;
          line-height: 32px;
          margin: 16px 0;
        }
      }
      .img-container {
        padding: 32px;
      }
    }
  }

  .sixth-container {
    .container {
      flex-direction: column;
      .first-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 64px;
        h1 {
          font-size: 48px;
          line-height: 64px;
        }
      }
      .second-container {
        width: 100%;
      }
    }
  }

  .seventh-container {
    .container {
      .first-container {
        h1 {
          font-size: 48px;
          line-height: 64px;
        }
        h5 {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  .eighth-container {
    .container {
      .first-container {
        width: 47%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }
      .second-container {
        width: 47%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }
      .third-container {
        width: 47%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }

      .fourth-container {
        width: 47%;
        .xample {
          padding: 64px 176px;
          img {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .f-container {
    padding: 32px 16px 31px 16px;
    .container {
      flex-direction: column;
      .first-container {
        margin: 0 0 32px 0;
        .text-con {
          color: #222222;
          h1 {
            font-size: 48px;
            line-height: 64px;
            font-weight: 900;
          }
          h5 {
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            padding-bottom: 43px;
            padding-top: 16px;
          }
        }
      }
      .second-container {
        padding: 0;
        display: flex;
        // img {
        //   margin: 0;
        //   width: 100%;
        //   height: 97.702%;
        // }
      }
    }
  }

  .s-container {
    padding: 32px 16px 31px 16px;
    .container {
      flex-direction: column;
      .first-container {
        padding: 0 0 16px 0;
        h1 {
          font-size: 32px;
          padding: 32px 16px 16px;
          line-height: 48px;
          font-weight: 900;
        }
        h5 {
          font-size: 16px;
          padding: 0 16px 16px 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .img-container {
        padding: 0 16px;
        justify-content: center;
        img {
          width: 256px;
          height: 277px;
        }
      }
      // .second-container {
      //   padding: 0;
      //   img {
      //     height: 281.38px;
      //     width: 288px;
      //   }
      // }
    }
  }

  .th-container {
    padding: 32px 16px 31px 16px;
    .container {
      flex-direction: column;
      .first-container {
        margin: 0;
        margin-bottom: 8px;
        width: 100%;
        .inside-first {
          padding: 16px;
          margin: 0;
          border-radius: 32px;
          h1 {
            font-size: 20px;
            line-height: 28px;
            padding-top: 16px;
            font-weight: 900;
          }
          h5 {
            padding-top: 16px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }
      }
      .second-container {
        margin: 0;
        width: 100%;
        margin-top: 8px;
        .inside-second {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          margin: 0;
          border-radius: 32px;
          h1 {
            font-size: 20px;
            line-height: 28px;
            padding-top: 16px;
            font-weight: 900;
          }
          h5 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            padding-top: 16px;
          }
        }
      }
    }
  }

  .fth-container {
    padding: 32px 16px 31px 16px;
    flex-direction: column-reverse;
    .container {
      flex-direction: column-reverse;
      .first-container {
        padding: 0 0 16px 0;
        h1 {
          font-size: 32px;
          padding: 32px 16px 16px;
          line-height: 48px;
          font-weight: 900;
        }
        h5 {
          font-size: 16px;
          padding: 0 16px 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .img-container {
        padding: 0 16px;
        justify-content: center;

        img {
          width: 256px;
          height: 277px;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-left-radius: 0;
          background-color: white;
          border-bottom-right-radius: 0;
        }
      }
      // .second-container {
      //   padding: 0;
      //   img {
      //     height: 281.38px;
      //     width: 288px;
      //   }
      // }
    }
  }

  .fith-container {
    padding: 32px 16px 31px 16px;
    .container {
      flex-direction: column;
      .first-container {
        padding: 0 16px;
        h1 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900;
        }
      }
      .img-container {
        display: flex;
        align-items: center;
        padding: 16px 0px 0px 16px;
      }
    }
  }

  .sixth-container {
    padding: 32px 16px 31px 16px;
    .container {
      flex-direction: column;
      .first-container {
        width: 100%;
        padding-bottom: 32px;
        margin: 0;
        color: #222222;
        h1 {
          font-size: 48px;
          line-height: 64px;
          font-weight: 900;
        }
      }
      .second-container {
        width: 100%;
        margin: 0;

        .pb-32 {
          padding-bottom: 16px;
        }
        .pb-16 {
          padding-bottom: 8px;
        }
        .toggle-list {
          padding-top: 16px;
          h5 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 900;
          }
          span {
            height: 24px;
            img {
              height: 24px;
              width: 24px;
            }
          }
        }
        .list-content {
          padding-bottom: 16px;
        }
        .toggle-list:first-child {
          padding-top: 0px !important;
        }
        .toggle-list:last-child {
          border-bottom: none;
          padding-bottom: 0px !important;
        }
      }
    }
  }

  .seventh-container {
    padding: 32px 16px 31px 16px;
    .container {
      .first-container {
        padding: 32px 16px;
        h1 {
          font-size: 48px;
          line-height: 64px;
          font-weight: 900;
          padding-bottom: 16px;
        }
        h5 {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          padding-bottom: 32px;
        }
      }
    }
  }

  .eighth-container {
    .container {
      // border-bottom: 1px solid #222222;
      padding: 32px 0 32px 0;
      margin-left: 16px;
      justify-content: flex-start;
      .first-container {
        margin-right: 8px;
        width: 100%;
        .xample {
          padding: 32px 96px;
          img {
          }
        }

        .text-con {
          padding: 0px 16px 32px 16px;
          p {
            font-size: 20px;
            line-height: 28px;
            font-weight: 900;
            text-align: center;
          }
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }
      .second-container {
        width: 100%;

        margin: 0 8px;
        .xample {
          padding: 32px 96px;
          img {
          }
        }
        .text-con {
          padding: 0px 16px 32px 16px;
          p {
            font-size: 20px;
            line-height: 28px;
            font-weight: 900;
            text-align: center;
          }
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }
      .third-container {
        margin: 0 8px;
        width: 100%;
        .xample {
          padding: 32px 96px;
          img {
          }
        }
        .text-con {
          padding: 0px 16px 32px 16px;
          p {
            font-size: 20px;
            line-height: 28px;
            font-weight: 900;
            text-align: center;
          }
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }

      .fourth-container {
        margin-left: 8px;
        width: 100%;
        .xample {
          padding: 32px 96px;
          img {
          }
        }
        .text-con {
          padding: 0px 16px 32px 16px;
          p {
            font-size: 20px;
            line-height: 28px;
            font-weight: 900;
            text-align: center;
          }
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }

      .empty-container {
        padding: 0 8px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .f-container {
    padding: 32px 16px 31px 16px;
    .container {
      flex-direction: column;
      .first-container {
        margin: 0 0 32px 0;
        .text-con {
          color: #222222;
          h1 {
            font-size: 48px;
            line-height: 64px;
            font-weight: 900;
          }
          h5 {
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            padding-bottom: 32px;
            padding-top: 16px;
          }
        }
      }
    }
  }
  .fth-container {
    .container {
      .img-container {
        img {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .fith-container {
    .container {
      .first-container {
        padding: 0 16px;
        h1 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900;
        }
      }
      .img-container {
        display: flex;
        align-items: center;
        padding: 16px 0px 0px 16px;
      }
    }
  }
  .eighth-container {
    .container {
      .first-container {
        .text-con {
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }
      .second-container {
        .text-con {
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }
      .third-container {
        .text-con {
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }
      .fourth-container {
        .text-con {
          p:first-child {
            padding-bottom: 28px;
          }
        }
      }
    }
  }
}
</style>
